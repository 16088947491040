import React from "react";
import styles from './style.scss';
function IndoorGames() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="108"
      // height="108"
      // fill="none"
      viewBox="0 0 108 108"
      className="IndoorGamesSvg"
    >
      <path
        // fill="#8874A3"
        d="M70.2 29.7V0H37.8v29.7L54 45.9l16.2-16.2zm-40.5 8.1H0v32.4h29.7L45.9 54 29.7 37.8zm8.1 40.5V108h32.4V78.3L54 62.1 37.8 78.3zm40.5-40.5L62.1 54l16.2 16.2H108V37.8H78.3z"
      ></path>
    </svg>
  );
}

export default IndoorGames;