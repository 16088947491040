import React from "react";
import styles from './style.scss';
function NewHire() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="128"
      // height="76"
      // fill="red"
      viewBox="0 0 128 76"
      className="newHireSvg"
    >
      <path
        // fill=""
        d="M64.68 4.196L45.32 19.714c-3.22 2.574-3.84 7.205-1.4 10.51 2.58 3.524 7.6 4.217 11.06 1.545l19.86-15.281a3.235 3.235 0 014.5.554c1.1 1.386.84 3.365-.56 4.454l-4.18 3.206 27.8 25.336v-37.37h-.14l-.78-.495-14.52-9.204A19.23 19.23 0 0076.68 0c-4.36 0-8.6 1.485-12 4.196zm4.56 24.623L58.9 36.776c-6.3 4.87-15.44 3.603-20.16-2.81-4.44-6.037-3.32-14.47 2.54-19.16L57.92 1.485A19.068 19.068 0 0050.56.02C46.8 0 43.14 1.108 40 3.167l-14.4 9.5v44.338h5.64l18.28 16.508c3.92 3.543 9.98 3.266 13.56-.613a9.65 9.65 0 002.22-4.078l3.4 3.088c3.9 3.543 9.98 3.286 13.56-.574a9.57 9.57 0 001.98-3.266c3.88 2.573 9.16 2.039 12.42-1.484a9.437 9.437 0 00-.58-13.42L69.24 28.819zM19.2 12.668H0v44.337c0 3.504 2.86 6.334 6.4 6.334h6.4c3.54 0 6.4-2.83 6.4-6.334V12.668zM9.6 57.005c-1.76 0-3.2-1.425-3.2-3.166 0-1.742 1.44-3.167 3.2-3.167 1.76 0 3.2 1.425 3.2 3.167 0 1.741-1.44 3.166-3.2 3.166zm99.2-44.337v44.337c0 3.504 2.86 6.334 6.4 6.334h6.4c3.54 0 6.4-2.83 6.4-6.334V12.668h-19.2zm12.8 41.17c0 1.742-1.44 3.167-3.2 3.167-1.76 0-3.2-1.425-3.2-3.166 0-1.742 1.44-3.167 3.2-3.167 1.76 0 3.2 1.425 3.2 3.167z"
      ></path>
    </svg>
  );
}

export default NewHire;