// extracted by mini-css-extract-plugin
export var cardSvg = "styles-module--cardSvg--8sgPO";
export var cardTitle = "styles-module--cardTitle--1ehM6";
export var headLine = "styles-module--headLine--7slc-";
export var headTxt = "styles-module--headTxt--axx4f";
export var hoverColor = "styles-module--hoverColor--BglIX";
export var hoverLight = "styles-module--hoverLight--k71ER";
export var hoverMirror = "styles-module--hoverMirror--HJCcI";
export var keyCard = "styles-module--keyCard--xCkDA";
export var keyCardHead = "styles-module--keyCardHead--9O7oG";
export var keyCardTxt = "styles-module--keyCardTxt--EE5nP";
export var learningenvt = "styles-module--learningenvt--5+O3n";
export var noteHeading = "styles-module--noteHeading--COCCd";
export var noteHeadingTxt = "styles-module--noteHeadingTxt--Plt2a";
export var noteMain = "styles-module--noteMain--O3v73";
export var noteSub = "styles-module--noteSub--ibivj";
export var notesMain = "styles-module--notesMain--jCCGN";
export var notesSub = "styles-module--notesSub--h7VXf";
export var notesTxt = "styles-module--notesTxt--HUUFn";
export var notesTxtCont = "styles-module--notesTxtCont--p6Kfp";
export var offerBody = "styles-module--offerBody--UHHVE";
export var offerCard = "styles-module--offerCard--ifvkd";
export var offerCardInner = "styles-module--offerCardInner--sbjYV";
export var offerCardInner1 = "styles-module--offerCardInner1--G9MSD";
export var offerCardInner2 = "styles-module--offerCardInner2--VMr5q";
export var offerCardInner3 = "styles-module--offerCardInner3--fkQPv";
export var offerCardInner4 = "styles-module--offerCardInner4--XGpnJ";
export var offerCardInner5 = "styles-module--offerCardInner5--pMvwG";
export var offerCardInner6 = "styles-module--offerCardInner6--UGjO5";
export var offerCardInner7 = "styles-module--offerCardInner7--wWjI-";
export var offerCardInner8 = "styles-module--offerCardInner8--tA1f+";
export var offerCardInner9 = "styles-module--offerCardInner9--wuToI";
export var offerSecHead = "styles-module--offerSecHead--pg+cN";
export var overlay = "styles-module--overlay--puvlg";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";