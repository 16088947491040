import React from "react";
import styles from './style.scss';
function MovieNight() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="116"
    // height="106"
    // fill="none"
    viewBox="0 0 116 106"
    className="MovieNightSvg"
  >
    <path
      // fill="#29A8AB"
      d="M71.775.002a23.945 23.945 0 00-13.105 3.9 23.912 23.912 0 00-8.827 10.433 23.908 23.908 0 00-6.742-8.905A23.938 23.938 0 0021.87.775a23.936 23.936 0 00-9.855 5.266 23.885 23.885 0 00-4.241 30.771 23.919 23.919 0 008.063 7.73 11.965 11.965 0 00-5.702 4.378 11.946 11.946 0 00-2.161 6.853v3.983H3.987v-1.991a1.991 1.991 0 00-1.993-1.992A1.995 1.995 0 000 57.765v19.918a1.991 1.991 0 001.994 1.991 1.995 1.995 0 001.993-1.991v-5.976h3.988v19.918c0 3.17 1.26 6.21 3.504 8.451a11.969 11.969 0 008.458 3.5h55.825c3.173 0 6.215-1.259 8.459-3.5a11.946 11.946 0 003.503-8.45v-.47a7.962 7.962 0 004.41 7.126l11.963 5.975a7.986 7.986 0 003.925.833 7.99 7.99 0 003.833-1.183 7.963 7.963 0 003.782-6.776V50.267a7.96 7.96 0 00-3.782-6.776 7.981 7.981 0 00-7.758-.35l-11.962 5.975a7.973 7.973 0 00-4.41 7.135v-.478c0-4.338-2.313-8.135-5.774-10.23a23.914 23.914 0 0011.316-11.108 23.88 23.88 0 001.873-15.739 23.898 23.898 0 00-8.395-13.448A23.94 23.94 0 0071.775.002zm-15.95 23.902c0-4.226 1.68-8.28 4.671-11.268a15.958 15.958 0 0122.557 0 15.927 15.927 0 010 22.535 15.958 15.958 0 01-22.557 0 15.927 15.927 0 01-4.671-11.267zm51.837 26.363v46.864l-11.963-5.976V56.243l11.963-5.976zM19.937 51.79h55.825a3.99 3.99 0 013.987 3.984v35.852a3.982 3.982 0 01-3.987 3.984H19.937a3.99 3.99 0 01-3.987-3.984V55.773a3.982 3.982 0 013.987-3.984zm7.975-43.82c4.23 0 8.287 1.68 11.279 4.667a15.927 15.927 0 010 22.535 15.958 15.958 0 01-22.557 0 15.927 15.927 0 010-22.535A15.958 15.958 0 0127.912 7.97z"
    ></path>
  </svg>
  );
}

export default MovieNight;