import React from "react";
import styles from './style.scss';
function Parking() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="107"
      // height="93"
      // fill="none"
      viewBox="0 0 107 93"
      className="ParkingSvg"
    >
      <path
        // fill="#02285B"
        d="M28.255 17.728L22.8 33.214h61.4l-5.455-15.486a6.69 6.69 0 00-6.311-4.442H34.566a6.69 6.69 0 00-6.311 4.442zM8.275 34.211l7.357-20.863C18.453 5.356 26.04 0 34.566 0h37.868c8.526 0 16.113 5.356 18.934 13.348l7.356 20.863C103.573 36.204 107 40.957 107 46.5v39.857c0 3.674-2.988 6.643-6.688 6.643h-6.687c-3.699 0-6.688-2.969-6.688-6.643v-9.964H20.064v9.964c0 3.674-2.989 6.643-6.688 6.643H6.687C2.989 93 0 90.031 0 86.357V46.5c0-5.543 3.427-10.296 8.276-12.29zM26.75 53.143c0-3.675-2.988-6.643-6.688-6.643-3.699 0-6.687 2.968-6.687 6.643 0 3.674 2.988 6.643 6.688 6.643 3.699 0 6.687-2.969 6.687-6.643zm60.188 6.643c3.698 0 6.687-2.969 6.687-6.643 0-3.675-2.989-6.643-6.688-6.643-3.698 0-6.687 2.968-6.687 6.643 0 3.674 2.989 6.643 6.688 6.643z"
      ></path>
    </svg>
  );
}

export default Parking;