import React from "react"
import KeyNotesCard from "components/pages/KeyNotesCard"
import ProjectCard from "components/pages/CareerCard"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import { Helmet } from "react-helmet"

const AdminProject = () => {
	return (
		<>
			<Helmet>
				<html lang="en" />
				<title>MercurySols Technologies | Career </title>
				<meta
					name="description"
					content="MercurySols is a leading software and web development company providing IT solutions, e-commerce solutions, and web design services. We offer a learning and healthy work environment with employee growth plans, welcome packages, and recreational tours. Join our team of talented individuals to work on the latest technology and experience professional growth."
				/>
			</Helmet>
			<HeaderTwo />
			<div>
				<KeyNotesCard />
				<ProjectCard />
			</div>
			<Footer />
		</>
	)
}

export default AdminProject
