import React from "react"
import NodejsSvg from "assets/svgs/NodejsSvg"
import { navigate } from "gatsby"
import { Col } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import CompanyLogo from "components/CompanyLogo"
const InfoCard = () => {
	const skills = [
		{
			content: "Node Js",
			styleObj: {
				backgroundColor: "#7259d433",
				border: "1px solid #5b39cb8f",
			},
		},
		{
			content: "MongoDB",
			styleObj: {
				backgroundColor: "#83cd2936",
				border: "1px solid #83cd2991",
			},
		},
		{
			content: "Typescript",
			styleObj: {
				backgroundColor: "#03a8f51a",
				border: "1px solid #03a8f566",
			},
		},
	]
	const locationsStyleObj = {
		backgroundColor: "#ffc21a33",
		border: "1px solid #ffc21a91",
	}
	return (
		<>
			<div className={styles.careerMain}>
				<div className={styles.careerFirstDiv}>
					<Col xs={12} sm={8} md={6} lg={4}>
						<div>
							<div className={styles.icMain}>
								<CompanyLogo />
								<div className="d-flex justify-content-center">
									<NodejsSvg />
								</div>
								<div>
									<h2 className={styles.icTitle}>Node.Js Developer</h2>
									<h5 className={styles.icExp}>2-5 Years - Experience</h5>
									<h5 className={styles.icDescHeading}>Job Description</h5>
								</div>
								<div className={styles.icListMain}>
									<ul className="p-0">
										<li className={styles.icListItem}>Knows and have implemented Serverless Architecture</li>
										<li className={styles.icListItem}>Worked with NoSQL and AWS Dynamo DB (optional)</li>
										<li className={styles.icListItem}>Knows and implemented CI/CD</li>
										<li className={styles.icListItem}>Knows and implemented CI/CD</li>
										<li className={styles.icListItem}>
											Other backend expectations like strong DB skills and optimization of code etc
										</li>
										<li className={styles.icListItem}>Good foundations on OOPS</li>
										<li className={styles.icListItem}>
											Knowledge of PHP frameworks like Codeigniter, CakePHP, Laravel
										</li>
										<li className={styles.icListItem}>Good Hands on Server Knowledge</li>
									</ul>
								</div>
								<div className={styles.icBar}>
									<div className={styles.icBarHead}>
										<span className={styles.icBarTxt}>Key Skills:</span>
									</div>
									<div className="d-flex flex-wrap">
										{skills.map((item, index) => {
											return (
												<span key={index} style={item.styleObj} className={styles.skillChips}>
													{item.content}
												</span>
											)
										})}
									</div>
								</div>
								<div className={`py-3 ${styles.icBar}`}>
									<div className={styles.icBarHead}>
										<span className={styles.icBarTxt}>Job Location:</span>
									</div>
									<div>
										<span style={locationsStyleObj} className={styles.skillChips}>
											Rahim Yar Khan
										</span>
									</div>
								</div>
								<div className={styles.icBtns}>
									<button
										onClick={() => {
											if (typeof window !== "undefined") {
												localStorage.setItem("activePage", 0)
											}
											navigate("/jobapply")
										}}
										className={styles.icDeleteBtnn}
									>
										Apply Now
									</button>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={8} md={6} lg={4}>
						<div>
							<div className={styles.icMain}>
								<CompanyLogo />
								<div className="d-flex justify-content-center">
									<NodejsSvg />
								</div>
								<div>
									<h2 className={styles.icTitle}>Node.Js Developer</h2>
									<h5 className={styles.icExp}>2-5 Years - Experience</h5>
									<h5 className={styles.icDescHeading}>Job Description</h5>
								</div>
								<div className={styles.icListMain}>
									<ul className="p-0">
										<li className={styles.icListItem}>Knows and have implemented Serverless Architecture</li>
										<li className={styles.icListItem}>Worked with NoSQL and AWS Dynamo DB (optional)</li>
										<li className={styles.icListItem}>Knows and implemented CI/CD</li>
										<li className={styles.icListItem}>Knows and implemented CI/CD</li>
										<li className={styles.icListItem}>
											Other backend expectations like strong DB skills and optimization of code etc
										</li>
										<li className={styles.icListItem}>Good foundations on OOPS</li>
										<li className={styles.icListItem}>
											Knowledge of PHP frameworks like Codeigniter, CakePHP, Laravel
										</li>
										<li className={styles.icListItem}>Good Hands on Server Knowledge</li>
									</ul>
								</div>
								<div className={styles.icBar}>
									<div className={styles.icBarHead}>
										<span className={styles.icBarTxt}>Key Skills:</span>
									</div>
									<div className="d-flex flex-wrap">
										{skills.map((item, index) => {
											return (
												<span key={index} style={item.styleObj} className={styles.skillChips}>
													{item.content}
												</span>
											)
										})}
									</div>
								</div>
								<div className={`py-3 ${styles.icBar}`}>
									<div className={styles.icBarHead}>
										<span className={styles.icBarTxt}>Job Location:</span>
									</div>
									<div>
										<span style={locationsStyleObj} className={styles.skillChips}>
											Rahim Yar Khan
										</span>
									</div>
								</div>
								<div className={styles.icBtns}>
									<button
										onClick={() => {
											if (typeof window !== "undefined") {
												localStorage.setItem("activePage", 0)
											}
											navigate("/jobapply")
										}}
										className={styles.icDeleteBtnn}
									>
										Apply Now
									</button>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={8} md={6} lg={4}>
						<div>
							<div className={styles.icMain}>
								<CompanyLogo />
								<div className="d-flex justify-content-center">
									<NodejsSvg />
								</div>
								<div>
									<h2 className={styles.icTitle}>Node.Js Developer</h2>
									<h5 className={styles.icExp}>2-5 Years - Experience</h5>
									<h5 className={styles.icDescHeading}>Job Description</h5>
								</div>
								<div className={styles.icListMain}>
									<ul className="p-0">
										<li className={styles.icListItem}>Knows and have implemented Serverless Architecture</li>
										<li className={styles.icListItem}>Worked with NoSQL and AWS Dynamo DB (optional)</li>
										<li className={styles.icListItem}>Knows and implemented CI/CD</li>
										<li className={styles.icListItem}>Knows and implemented CI/CD</li>
										<li className={styles.icListItem}>
											Other backend expectations like strong DB skills and optimization of code etc
										</li>
										<li className={styles.icListItem}>Good foundations on OOPS</li>
										<li className={styles.icListItem}>
											Knowledge of PHP frameworks like Codeigniter, CakePHP, Laravel
										</li>
										<li className={styles.icListItem}>Good Hands on Server Knowledge</li>
									</ul>
								</div>
								<div className={styles.icBar}>
									<div className={styles.icBarHead}>
										<span className={styles.icBarTxt}>Key Skills:</span>
									</div>
									<div className="d-flex flex-wrap">
										{skills.map((item, index) => {
											return (
												<span key={index} style={item.styleObj} className={styles.skillChips}>
													{item.content}
												</span>
											)
										})}
									</div>
								</div>
								<div className={`py-3 ${styles.icBar}`}>
									<div className={styles.icBarHead}>
										<span className={styles.icBarTxt}>Job Location:</span>
									</div>
									<div>
										<span style={locationsStyleObj} className={styles.skillChips}>
											Rahim Yar Khan
										</span>
									</div>
								</div>
								<div className={styles.icBtns}>
									<button
										onClick={() => {
											if (typeof window !== "undefined") {
												localStorage.setItem("activePage", 0)
											}
											navigate("/jobapply")
										}}
										className={styles.icDeleteBtnn}
									>
										Apply Now
									</button>
								</div>
							</div>
						</div>
					</Col>
				</div>
			</div>
		</>
	)
}
export default InfoCard
