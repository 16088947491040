import React from "react"
import * as styles from "./styles.module.scss"
import AOS from "aos"
import "aos/dist/aos.css"
import { StaticImage } from "gatsby-plugin-image"
import NewHire from "assets/svgs/newHire"
import Rewards from "assets/svgs/rewards"
import EmpGrowth from "assets/svgs/growth"
import MovieNight from "assets/svgs/movieNight"
import Parking from "assets/svgs/parking"
import IndoorGames from "assets/svgs/indoorGames"
// import Tours from "assets/svgs/tours"
import CompanyDinners from "assets/svgs/companyDinners"
import Relaxation from "assets/svgs/relaxationSpaces"
import { Container, Col } from "react-bootstrap"
const KeyNotesCard = () => {
	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
	}
	return (
		<div className={`${styles.notesMain} mt-5 pt-3`}>
			<div className={styles.notesSub}>
				<div className={styles.notesTxtCont}>
					<div style={{ width: "auto" }}>
						<Container>
							<div className="learningenvt" style={{ textAlign: "center" }}>
								<StaticImage
									src="../../../assets/svgs/newCareer.svg"
									alt="Website IT Company"
									placeholder="none"
									style={{ width: "60%", height: "100%" }}
								/>
							</div>
						</Container>
					</div>
					<h1 className={styles.notesTxt} style={{ position: "absolute", top: "50px" }}>
						A Learning and healthy work environment
					</h1>
				</div>

				<div className={styles.offerSec}>
					<div className={styles.offerSecHead}>
						<h2 className={styles.headTxt}>What We Offer</h2>
						<span className={styles.headLine}></span>
					</div>
					<div className={styles.offerBody}>
						<Col xs={6} sm={4} md={3} lg={2}>
							<div className={styles.offerCard}>
								<div className={`${styles.offerCardInner} ${styles.offerCardInner1}`}>
									<div className={styles.cardSvg}>
										<span>
											<NewHire />
										</span>
									</div>
									<div className={styles.cardTitle}>Welcome Packages</div>
								</div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={3} lg={2}>
							<div className={styles.offerCard}>
								<div className={`${styles.offerCardInner} ${styles.offerCardInner2}`}>
									<div className={styles.cardSvg}>
										<span>
											<Rewards />
										</span>
									</div>
									<div className={styles.cardTitle}>Gifts & Rewards</div>
								</div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={3} lg={2}>
							<div className={styles.offerCard}>
								<div className={`${styles.offerCardInner} ${styles.offerCardInner3}`}>
									<div className={styles.cardSvg}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="60"
											height="50"
											fill="none"
											viewBox="0 0 110 101"
											className="ToursSvg"
										>
											<path
												stroke="#FF8881"
												strokeLinejoin="round"
												strokeWidth="4"
												d="M10.753 47.592h87.543v29.5c0 1.422-.615 2.787-1.71 3.793-1.094 1.005-2.579 1.57-4.127 1.57H16.59c-1.548 0-3.032-.565-4.127-1.57-1.094-1.006-1.71-2.37-1.71-3.793v-29.5zm0-40.228c0-1.423.615-2.787 1.71-3.793C13.556 2.565 15.04 2 16.588 2h75.87c1.548 0 3.033.565 4.127 1.571 1.095 1.006 1.71 2.37 1.71 3.793V47.59H10.752V7.365z"
											></path>
											<path
												stroke="#FF8881"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="4"
												d="M28.264 98.547c-2.322 0-4.548-.847-6.19-2.356-1.642-1.51-2.564-3.556-2.564-5.69v-8.045h17.508v8.046c0 2.133-.922 4.18-2.564 5.689-1.642 1.508-3.868 2.356-6.19 2.356v0zm52.526 0c-2.322 0-4.549-.847-6.19-2.356-1.642-1.51-2.565-3.556-2.565-5.69v-8.045h17.509v8.046c0 2.133-.922 4.18-2.564 5.689-1.642 1.508-3.869 2.356-6.19 2.356z"
											></path>
											<path
												className={styles.hoverMirror}
												stroke="#fff"
												strokeLinecap="round"
												strokeWidth="4"
												d="M2 18.09v10.728m105.051-10.727v10.727"
											></path>
											<path
												className={styles.hoverLight}
												fill="#FF8881"
												d="M28.263 71.728c3.223 0 5.836-2.401 5.836-5.363 0-2.963-2.613-5.364-5.836-5.364-3.223 0-5.836 2.401-5.836 5.364 0 2.962 2.613 5.363 5.836 5.363zM80.79 71.728c3.222 0 5.835-2.401 5.835-5.363 0-2.963-2.612-5.364-5.836-5.364-3.223 0-5.836 2.401-5.836 5.364 0 2.962 2.613 5.363 5.836 5.363z"
											></path>
											<path
												className={styles.hoverColor}
												stroke="#FF8881"
												strokeLinecap="round"
												strokeWidth="4"
												d="M74.952 2L48.69 28.819M95.379 4.681l-14.59 16.091"
											></path>
										</svg>
									</div>
									<div className={styles.cardTitle}>Recreational Tours</div>
								</div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={3} lg={2}>
							<div className={styles.offerCard}>
								<div className={`${styles.offerCardInner} ${styles.offerCardInner4}`}>
									<div className={styles.cardSvg}>
										<span>
											<EmpGrowth />
										</span>
									</div>
									<div className={styles.cardTitle}>Employee Growth Plan</div>
								</div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={3} lg={2}>
							<div className={styles.offerCard}>
								<div
									className={`${styles.offerCardInner} ${styles.offerCardInner5}`}
									// style={{ borderColor: "#02285B" }}
								>
									<div className={styles.cardSvg}>
										<span>
											<Parking />
										</span>
									</div>
									<div className={styles.cardTitle}>Parking</div>
								</div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={3} lg={2}>
							<div className={styles.offerCard}>
								<div
									className={`${styles.offerCardInner} ${styles.offerCardInner6}`}
									// style={{ borderColor: "#8874A3" }}
								>
									<div className={styles.cardSvg}>
										<span>
											<IndoorGames />
										</span>
									</div>
									<div className={styles.cardTitle}>Indoor Games</div>
								</div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={3} lg={2}>
							<div className={styles.offerCard}>
								<div className={`${styles.offerCardInner} ${styles.offerCardInner7}`}>
									<div className={styles.cardSvg}>
										<span>
											<MovieNight />
										</span>
									</div>
									<div className={styles.cardTitle}>Movie Night</div>
								</div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={3} lg={2}>
							<div className={styles.offerCard}>
								<div className={`${styles.offerCardInner} ${styles.offerCardInner8}`}>
									<div className={styles.cardSvg}>
										<span>
											<CompanyDinners />
										</span>
									</div>
									<div className={styles.cardTitle}>Company Dinners</div>
								</div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={3} lg={2}>
							<div className={styles.offerCard}>
								<div className={`${styles.offerCardInner} ${styles.offerCardInner9}`}>
									<div className={styles.cardSvg}>
										<span>
											<Relaxation />
										</span>
									</div>
									<div className={styles.cardTitle}>Relaxation Spaces</div>
								</div>
							</div>
						</Col>
					</div>
				</div>
				<div className={styles.noteMain}>
					<div className={styles.noteSub}>
						<h2 className={styles.noteHeading}>Looking for talented & skilled individuals</h2>
						<h2 className={styles.noteHeadingTxt}>
							Mercurysols provides you with a chance to work on the latest technology and experience gradual
							professional growth
						</h2>
					</div>
				</div>
			</div>
		</div>
	)
}
export default KeyNotesCard
