import React from "react";
import styles from './style.scss';
function EmpGrowth() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="132"
      // height="92"
      // fill="none"
      viewBox="0 0 132 92"
      className="EmpGrowthSvg"
    >
      <path
        // fill="#29A8AB"
        d="M125.4 82.8h-6.6v-69c0-1.22-.695-2.39-1.933-3.253-1.238-.862-2.917-1.347-4.667-1.347s-3.429.485-4.667 1.347c-1.238.863-1.933 2.033-1.933 3.253v69H92.4V32.2c0-1.22-.695-2.39-1.933-3.253-1.238-.862-2.917-1.347-4.667-1.347s-3.43.485-4.667 1.347C79.895 29.81 79.2 30.98 79.2 32.2v50.6H66V50.6c0-1.22-.695-2.39-1.933-3.253C62.829 46.485 61.15 46 59.4 46s-3.43.485-4.667 1.347C53.495 48.21 52.8 49.38 52.8 50.6v32.2H39.6V69c0-1.22-.695-2.39-1.933-3.253C36.429 64.885 34.75 64.4 33 64.4s-3.43.485-4.667 1.347C27.095 66.61 26.4 67.78 26.4 69v13.8H13.2V4.6c0-1.22-.695-2.39-1.933-3.253C10.029.485 8.35 0 6.6 0S3.17.485 1.933 1.347C.695 2.21 0 3.38 0 4.6v82.8c0 1.22.695 2.39 1.933 3.253C3.171 91.515 4.85 92 6.6 92h118.8c1.75 0 3.429-.485 4.667-1.347C131.305 89.79 132 88.62 132 87.4c0-1.22-.695-2.39-1.933-3.253-1.238-.862-2.917-1.347-4.667-1.347z"
      ></path>
    </svg>
  );
}

export default EmpGrowth;