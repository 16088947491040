// extracted by mini-css-extract-plugin
export var careerFirstDiv = "styles-module--careerFirstDiv--Zd+SH";
export var careerMain = "styles-module--careerMain--ysu7s";
export var icBar = "styles-module--icBar--82l7Y";
export var icBarDescTxt = "styles-module--icBarDescTxt--Zdjmu";
export var icBarHead = "styles-module--icBarHead--GNIJA";
export var icBarTxt = "styles-module--icBarTxt--+SErk";
export var icBarTxtLine = "styles-module--icBarTxtLine--dDO-w";
export var icBtns = "styles-module--icBtns--r9y5d";
export var icDeleteBtn = "styles-module--icDeleteBtn--VyzDh";
export var icDeleteBtnn = "styles-module--icDeleteBtnn--VcYYI";
export var icDescHeading = "styles-module--icDescHeading--v9bCs";
export var icExp = "styles-module--icExp--s5csh";
export var icExp1 = "styles-module--icExp1--RNwfQ";
export var icLine = "styles-module--icLine--pfQ0u";
export var icListItem = "styles-module--icListItem--lHIHP";
export var icListItemTick = "styles-module--icListItemTick--WxLyx";
export var icListMain = "styles-module--icListMain--tX8rN";
export var icMain = "styles-module--icMain--T2WU7";
export var icTitle = "styles-module--icTitle--ArHJO";
export var icTitle1 = "styles-module--icTitle1--Yy8Jq";
export var icUpdateBtn = "styles-module--icUpdateBtn--hQl1M";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";
export var skillChips = "styles-module--skillChips--4uuqj";