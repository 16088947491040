import React from "react";
import styles from './style.scss';
function CompanyDinners() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="121"
      // height="92"
      className="companyDinners"
      // fill="none"
      viewBox="0 0 121 92"
    >
      <path
        // fill="#6497B1"
        d="M112.933 66.016c0-27.327-21.365-49.799-48.4-51.848V0h-8.066v14.168c-27.035 2.05-48.4 24.52-48.4 51.848v8.276h104.866v-8.276zm-8.066.276H16.133v-.276c0-24.262 19.903-44 44.367-44s44.367 19.738 44.367 44v.276zM0 84h121v8H0v-8z"
      ></path>
    </svg>
  );
}

export default CompanyDinners;