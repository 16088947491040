import React from "react";
import styles from './style.scss';
function Relaxation() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="102"
    // height="102"
    // fill="none"
    viewBox="0 0 102 102"
    className="RelaxationSvg"
  >
    <path
      // fill="#FF8881"
      d="M51 70.125c7.41 0 12.59-3.486 15.619-6.754a4.783 4.783 0 016.753-.278 4.783 4.783 0 01.26 6.753C69.267 74.607 61.697 79.687 51 79.687c-10.698 0-18.268-5.08-22.631-9.84-1.793-1.933-1.853-4.962.259-6.754a4.783 4.783 0 016.753.278C38.41 66.64 43.59 70.126 51 70.126zm-7.65-24.544l-.04-.06c-.04-.04-.08-.1-.14-.179-.139-.16-.318-.378-.557-.657-.498-.578-1.195-1.315-2.032-2.232-1.753-1.374-3.765-2.61-5.519-2.61-1.753 0-3.765 1.236-5.518 2.61-.837.917-1.534 1.654-2.032 2.232-.239.279-.598.498-.558.657-.239.08-.1.14-.14.18l-.039.06a1.578 1.578 0 01-1.773.557 1.604 1.604 0 01-1.096-1.514c0-3.566 1.335-7.092 3.307-9.722 1.953-2.59 4.762-4.622 7.85-4.622 3.087 0 5.896 2.032 7.849 4.622 1.972 2.63 3.307 6.156 3.307 9.722 0 .677-.438 1.295-1.096 1.514-.637.22-1.355 0-1.773-.558zm31.875 0l-.04-.06c-.04-.04-.08-.1-.14-.179-.139-.16-.318-.378-.557-.657-.498-.578-1.195-1.315-2.032-2.232-1.753-1.374-3.765-2.61-5.519-2.61-1.753 0-3.765 1.236-5.518 2.61-.837.917-1.534 1.654-2.032 2.232-.239.279-.598.498-.558.657-.239.08-.1.14-.14.18l-.039.06c-.418.557-1.136.776-1.773.557a1.604 1.604 0 01-1.096-1.514c0-3.566 1.335-7.092 3.307-9.722 1.953-2.59 4.762-4.622 7.85-4.622 3.087 0 5.896 2.032 7.849 4.622 1.972 2.63 3.307 6.156 3.307 9.722 0 .677-.438 1.295-1.096 1.514-.638.22-1.355 0-1.773-.558zM102 51c0 28.17-22.83 51-51 51S0 79.17 0 51 22.83 0 51 0s51 22.83 51 51zM51 9.562C28.11 9.563 9.562 28.11 9.562 51 9.563 73.89 28.11 92.438 51 92.438c22.89 0 41.438-18.548 41.438-41.438C92.438 28.11 73.89 9.562 51 9.562z"
    ></path>
  </svg>
  );
}

export default Relaxation;