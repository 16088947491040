import React from "react";
import styles from './style.scss';
function Rewards() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="97"
    // height="97"
    // fill="none"
    viewBox="0 0 97 97"
    className="RewardsSvg"
  >
    <path
      // fill="#6497B1"
      d="M36.09 13.034l6.594 11.216H28.797a7.576 7.576 0 01-7.578-7.578 7.576 7.576 0 017.578-7.578h.417a7.96 7.96 0 016.877 3.94zm-23.965 3.638c0 2.728.663 5.305 1.819 7.578H6.062A6.056 6.056 0 000 30.313v12.125A6.056 6.056 0 006.063 48.5h84.874A6.056 6.056 0 0097 42.437V30.313a6.056 6.056 0 00-6.063-6.063h-7.88a16.688 16.688 0 001.818-7.578C84.875 7.464 77.41 0 68.203 0h-.417a17.096 17.096 0 00-14.72 8.412L48.5 16.198l-4.566-7.767A17.074 17.074 0 0029.214 0h-.417C19.59 0 12.125 7.464 12.125 16.672zm63.656 0a7.576 7.576 0 01-7.578 7.578H54.316l6.593-11.216a7.984 7.984 0 016.877-3.94h.417a7.576 7.576 0 017.578 7.578zM6.062 54.562v33.344c0 5.02 4.074 9.094 9.094 9.094h27.282V54.562H6.062zM54.563 97h27.282c5.02 0 9.094-4.073 9.094-9.094V54.562H54.562V97z"
    ></path>
  </svg>
  );
}

export default Rewards;